import React from "react";
import Layout from "../Components/Layout";
import "../assets/sass/pages/nuostatos.scss";

function Privatumas() {
  return (
    <Layout>
    <div className="Rules">
      <div className="rulesMain">
        <h2>1. Bendrosios nuostatos</h2>
        <span>1.1. Lietuvos Respublikos Seimo kanceliarijos organizuojamo 2024 m. Demokratijos žinių konkurso (toliau – Konkursas) nuostatai reglamentuoja Konkurso tikslus, dalyvavimo jame sąlygas, Konkurso organizavimo, užduočių vertinimo ir apdovanojimo tvarką.<br />
              1.2. Konkurso tikslas – paskatinti aktyviau domėtis politiniu gyvenimu Lietuvoje ir pasaulyje, įgyti daugiau žinių apie įstatymų leidžiamosios institucijos veiklą ir sprendimų priėmimo procesą, demokratijos pagrindus, valstybės ir parlamentarizmo istoriją. <br />
              1.3. Konkursas yra skirtas suaugusiems ir 9–12 klasių moksleiviams, tačiau jame kviečiami dalyvauti ir žinias pasitikrinti visi bendrojo ugdymo mokyklų moksleiviai. Finaliniame etape galės dalyvauti tik moksleiviai, kurie finalo dieną bus ne jaunesni nei 14 metų ir ne vyresni nei 20 metų amžiaus. <br />
              1.4. Konkursą organizuoja Lietuvos Respublikos Seimo kanceliarija (toliau – Organizatorius). <br/>
              1.5. Konkurso informacinis partneris – Lietuvos nacionalinis radijas ir televizija. <br />
              1.6. Konkurso globėja – Lietuvos Respublikos Seimo Pirmininkė Viktorija Čmilytė-Nielsen.
        </span>
        <h2>2. Dalyvavimo konkurse sąlygos ir tvarka</h2>
        <span>
        2.1. Konkurse gali dalyvauti tik fiziniai asmenys.<br/>
        2.2. Konkurse negali dalyvauti Konkurso organizatoriai ir jų šeimos nariai.<br/>
        2.3. Konkurse norintys dalyvauti moksleiviai kviečiami registruotis iki balandžio 15 d. 23 val. 59 min. per elektroninę registracijos sistemą interneto svetainėje dzk.lrt.lt. <br/>
        2.4. Konkurso dalyvis, užpildęs elektroninę registracijos formą, patvirtina, kad dalyvauja Konkurse ir sutinka su Konkurso sąlygomis. <br/>
        2.5. Konkursą suaugusiems sudaro vienas etapas, moksleiviams du etapai – atrankos etapas (internetu) ir finalinis renginys (Seimo rūmuose). Pirmąjį etapą sudaro testo tipo klausimų sprendimas, finalinį – testo tipo klausimų sprendimas ir Seimo darbo simuliacija. Dėl ekstremaliosios situacijos ir (ar) karantino metu nustatytų ribojimų neturint galimybių rengti finalinio etapo Seimo rūmuose, jis būtų organizuojamas internetu ir jį sudarytų testo tipo klausimai. <br/>
        2.6. Konkurso klausimų sprendimas internetu prieinamas tiek kompiuteriuose, tiek mobiliuosiuose įrenginiuose.<br/>
        2.7. Pirmasis etapas internetu (dzk.lrt.lt) vyks 2024 m. nuo balandžio 16 d. 7 val. iki balandžio 17 d. 23.59 val.<br/>
        2.8. Finalinis etapas vyks Seimo rūmuose 2024 m. gegužės 11 d.<br/>
        </span>
        <h2>3. Konkurso užduočių sprendimas ir vertinimas</h2>
        <span>
        3.1. Pirmajam etapui parengiamas vienas klausimynas, kurį sudaro įvairaus sudėtingumo lygio klausimai. Dalyviui kompiuterinė programa atsitiktine tvarka parinks trisdešimt klausimų. Kiekvienam klausimui atsakyti bus skirta 1 minutė. Kiekvieną klausimą sudaro uždaras klausimas su keturiais atsakymų variantais, dalyviui leidžiant pasirinkti vieną atsakymą. Vieno teisingo atsakymo vertė – 1 taškas.<br/>
        3.2. Jeigu pirmojo etapo metu dalyviai surenka vienodą taškų skaičių, aukštesnę vietą užima greičiau į klausimus atsakęs dalyvis.<br/>
        3.3. Į finalinį renginį patenka 60 pirmajame etape geriausiai pasirodžiusių dalyvių.<br/>
        3.4. Organizatoriai turi teisę į papildomai į konkurso finalą pakviesti geriausiai Demokratijos žinių konkurso išvykų po Lietuvos mokyklas testą išsprendžiusius moksleivius.<br/>
        3.5. Pirmajame etape geriausiai pasirodę moksleiviai apie patekimą į finalinį renginį yra informuojami el. paštu, nurodytu registracijos metu. Į finalinį renginį patekę moksleiviai el. paštu taip pat informuojami apie planuojamą jo eigą.<br/>
        3.6. Į finalinį etapą patekę dalyviai per 5 dienas (terminas organizatorių sprendimu gali būti trumpinamas tai nurodant kvietimo tekste) nuo pranešimo apie patekimą į finalinį renginį el. paštu turi informuoti Organizatorių apie savo dalyvavimą finaliniame etape. Jeigu finalo dalyvis atsisako dalyvauti paskutiniame etape arba nepatvirtina savo dalyvavimo per nustatytą laiką, jo vietą siūloma užimti kitam geriausią rezultatą pirmajame etape užfiksavusiam moksleiviui.<br/>
        3.7. Finaliniame etape testas ir Seimo darbo simuliacija sudaro po 50 proc. galutinio įvertinimo. Jei susumavus atliktų užduočių rezultatus tarp kelių dalyvių rezultatas yra lygus, aukštesnę vietą užima daugiau taškų už testą gavęs dalyvis. Jei testo rezultatas taip pat yra lygus, tokiu atveju sprendimą dėl galutinės vietos priima konkurso komisija.<br/>
        </span>
        <h2>4. Konkurso laimėtojų paskelbimas ir apdovanojimai</h2>
        <span>
        4.1. Organizatoriaus sudaryta Konkurso vertinimo komisija vertina Konkurso dalyvių atliktas užduotis.<br/>
        4.2. Pirmojo etapo laimėtojais pripažįstami dalyviai, kurie pateikė daugiausia teisingų atsakymų į testo tipo uždarus klausimus ir išsprendė Konkurso užduotis per trumpiausią laiką.<br/>
        4.3. Finalinio etapo laimėtojais pripažįstami dalyviai, surinkę daugiausia taškų. Galutiniai konkurso rezultatai neskundžiami.<br/>
        4.4. Trys Konkurso laimėtojai yra apdovanojami Seimo rūmuose finalinio renginio dieną.<br/>
        4.5. Suaugusiųjų etapo nugalėtojais pripažįstami geriausiai pasirodžiusieji internetiniame etape.<br/>
        </span>
        <h2>5. Asmens duomenų tvarkymas</h2>
        <span>
        5.1. Organizatorius, siekdamas surengti Konkursą, naudoja dalyvių (prireikus – jų atstovų) asmens duomenis.<br/>
        5.2. Sprendimas pateikti asmens duomenis Organizatoriui yra savanoriškas. Jei dalyvis nepateikia reikalingos informacijos, jis negali dalyvauti Konkurse.<br/>
        5.3. Organizatorius tvarko šiuos Konkurso dalyvio asmens duomenis: vardas, pavardė, el. paštas, telefono numeris, mokykla, miestas, amžius, klasė, kurioje mokosi, informacija apie tai, kokiu būdu sužinota apie Konkursą (neprivalomas).<br/>
        5.4. Dalyvių asmens duomenys tvarkomi remiantis šiuo teisiniu pagrindu: Bendrojo duomenų apsaugos reglamento 6 straipsnio pirmosios dalies a punktu (sutikimas), c punktu (teisinės prievolės vykdymas – valstybinės institucijos veiklos viešinimas) ir f punktu (teisėtas organizatoriaus interesas populiarinti valstybinės institucijos veiklą).<br/>
        5.5. Jeigu asmens duomenys tvarkomi remiantis jų sutikimu, dalyviai turi teisę bet kuriuo metu atšaukti savo sutikimą. Tai neturi įtakos tvarkymo, atlikto remiantis sutikimu prieš jo atšaukimą, teisėtumui.<br/>
        5.6. Asmens duomenys perduodami tik paslaugų teikėjams – trečiosioms šalims, teikiančioms Organizatoriui Konkurso organizavimo paslaugas. Asmens duomenys perduodami šioms trečiosioms šalims ar užtikrinant prie jų prieigą tam, kad būtų galima šias paslaugas teikti Organizatoriui.<br/>
        5.7. Dalyvių asmens duomenys saugomi tik tiek laiko, kiek būtina Konkursui įgyvendinti. Teisinio ieškinio atveju asmens duomenys gali būti saugomi iki teismo bylos pabaigos. Jei pagal įstatymus privaloma išsaugoti asmens duomenis, Organizatorius laikysis atitinkamų nuostatų.<br/>
        </span>
        <h2>6. Baigiamosios nuostatos</h2>
        <span>
        6.1. Organizatoriai pasilieka teisę keisti Konkurso nuostatus.<br/>
        6.2. Finalinis renginys Seimo rūmuose bus filmuojamas, fotografuojamas ir ši vaizdinė informacija skelbiama viešai specialiame Konkurso puslapyje dzk.lrt.lt, Seimo svetainėje, Seimo socialinių tinklų paskyrose, kituose Seimo informacijos kanaluose. Nugalėtojų vardai, pavardės, atstovaujamos mokyklos, surinkti taškai, Konkursui pasibaigus, skelbiami viešai minėtais informacijos kanalais.<br/>
        6.3. Organizatorius yra tvarkomų duomenų valdytojas. Dalyviai, norėdami prašyti prieigos prie duomenų, juos ištaisyti, ištrinti, apriboti jų tvarkymą, prieštarauti tvarkymui, pasinaudoti duomenų perkeliamumo teisėmis ar išsakyti kitas pastabas dėl jų asmens duomenų tvarkymo, gali pateikti prašymą Seimo kanceliarijos duomenų apsaugos pareigūnui el. paštu dap@lrs.lt. Dalyviai taip pat gali pateikti skundus dėl savo asmens duomenų tvarkymo Valstybinei duomenų apsaugos inspekcijai.<br/>
        6.4. Konkurso metu gautų asmens duomenų apsaugai ir tvarkymo teisėtumui užtikrinti Organizatorius imasi visų būtinų, protingų ir pagrįstų priemonių.<br/>
        </span>
      </div>
    </div>
    </Layout>
  );
}

export default Privatumas;